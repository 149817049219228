<template>
  <div
    class="section section-download"
    id="contacto"
    data-background-color="black"
  >
    <div class="container" style="margin-top: -100px;">
      <div class="row justify-content-md-center sharing-area text-center">
        <div class="text-center col-md-12 col-lg-8">
          <h2>Contacto</h2>
          <h3>Llámanos para reservar<br>y comprobamos disponibilidad</h3>
        </div>
        <div class="text-center col-md-12 col-lg-8">
          <a
            href="tel:946042956"
            target="_blank"
            class="btn btn-primary btn-lg btn-simple"
            role="button"
            @click="sendGA4Event('call_click')"
          ><i class="fa fa-phone"></i>&nbsp;&nbsp; LLAMAR
          </a>
        </div>
        <div class="text-center col-md-12 col-lg-8 mt-3">
          <a
            target="_blank"
            href="https://www.instagram.com/restaurantetorreondo"
            class="btn btn-neutral btn-icon btn-instagram btn-lg"
            rel="tooltip"
            title="Síguenos en Instagram"
            @click="sendGA4Event('instagram_click')"
          >
            <font-awesome-icon :icon="['fab', 'instagram']" size="lg" style="margin-top: 18px !important;"/>
          </a>
          <a
            target="_blank"
            href="mailto:restaurantetorreondo@gmail.com"
            class="btn btn-neutral btn-icon btn-lg"
            rel="tooltip"
            title="Enviar email"
            @click="sendGA4Event('send_email')"
          >
            <font-awesome-icon :icon="['fa', 'envelope']" size="lg" style="margin-top: 18px !important;"/>
          </a>
        </div>
      </div>
    </div>
    <br>
  </div>
</template>
<script>
export default {
  methods: {
    sendGA4Event(event) {
      this.$gtag.event(event, {'value': true});
    }
  }
};
</script>
<style></style>
