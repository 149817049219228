<template>
  <div>
    <div class="page-header page-header-small">
      <video-background 
        src="vid/comedor.mp4"
        style="max-height: 450px; height: 120vh;"
        overlay="linear-gradient(180deg, rgba(2,0,36,0.7), rgba(0,32,87,0.4))"
      >
      </video-background>
      <!-- <parallax
        class="page-header-image"
        style="background-image: url('img/bg4.jpg'); opacity: 0.8;"
      >
      </parallax> -->
      <div class="content-center">
        <div class="container">
          <h1 class="title">La carta</h1>
          <div class="text-center">
            <!-- <h4>SUBTEXT</h4> -->
          </div>
        </div>
      </div>
    </div>
    <div class="section mb-1">
      <div class="container" style="margin-top: -50px !important;">

        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h3 class="title">Entrantes</h3>
            <div v-for="entrante in entrantes" :key="entrante.id">
              <h5 class="">{{ entrante.title }}</h5>
              <h5 class="mb-4" style="margin-top: -10px !important;">
                <b>{{ entrante.price }}</b>&nbsp;
                <img v-for="a in entrante.allergen" :key="a.id" :src="'icos/allergen/' + a + '.png'" height="40px" />
              </h5>
            </div>
          </div>
        </div>
        <hr style="width: 50%;">
        <div class="row" style="margin-top: -25px !important;">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h4 class="title">Carnes a la brasa</h4>
            <div v-for="carne in carnes" :key="carne.id">
              <h5 class="">{{ carne.title }}</h5>
              <h5 class="mb-4" style="margin-top: -10px !important;">
                <b>{{ carne.price }}</b>&nbsp;
                <img v-for="a in carne.allergen" :key="a.id" :src="'icos/allergen/' + a + '.png'" height="40px" />
              </h5>
            </div>
          </div>
        </div>
        <hr style="width: 50%;">
        <div class="row" style="margin-top: -25px !important;">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h4 class="title">Pescados bajo reserva</h4>
            <h5 class="description">Consultar con el personal</h5>
          </div>
        </div>
        <hr style="width: 50%;">
        <div class="row" style="margin-top: -25px !important;">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h4 class="title">Postres</h4>
            <div v-for="postre in postres" :key="postre.id">
              <h5 class="">{{ postre.title }}</h5>
              <h5 class="mb-4" style="margin-top: -10px !important;">
                <b>{{ postre.price }}</b>&nbsp;
                <img v-for="a in postre.allergen" :key="a.id" :src="'icos/allergen/' + a + '.png'" height="40px" />
              </h5>
            </div>
          </div>
        </div>
        <hr style="width: 90%;">
        <div class="row" style="margin-top: -15px !important;">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h4 style="font-size: 17px;">Sábado y domingo mediodía</h4>
            <h5 style="font-size: 16px;"><i>Todos los precios son con IVA incluído. Pan y servicio 2€.</i></h5>
          </div>
        </div>

        <!-- ALÉRGENOS -->
        <div class="row" style="margin-top: 20px !important;">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <img src="icos/allergen/huevos.png" height="60px" />
            <img src="icos/allergen/pescado.png" height="60px" />
            <img src="icos/allergen/moluscos.png" height="60px" />
            <img src="icos/allergen/cascara.png" height="60px" />
            <img src="icos/allergen/sesamo.png" height="60px" />
            <img src="icos/allergen/apio.png" height="60px" />
            <img src="icos/allergen/cacahuetes.png" height="60px" /><br>
            <img src="icos/allergen/lacteos.png" height="60px" />
            <img src="icos/allergen/mostaza.png" height="60px" />
            <img src="icos/allergen/crustaceos.png" height="60px" />
            <img src="icos/allergen/soja.png" height="60px" />
            <img src="icos/allergen/altramuces.png" height="60px" />
            <img src="icos/allergen/dioxido.png" height="60px" />
            <img src="icos/allergen/gluten.png" height="60px" />
            <!-- <h5 style="font-size: 16px;"><i>Todos los precios son con IVA incluído. Pan y servicio 2€.</i></h5> -->
          </div>
        </div>

      </div>
    </div> 
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
export default {
  name: 'carta',
  bodyClass: 'landing-page',
  metaInfo: {
    title: 'Restaurante Torreondo',
    titleTemplate: '%s | Carta',
    meta: [
      { name: 'description', content: 'Ven y disfruta de nuestra carta variada' },
      { property: 'og:title', content: 'Restaurante Torreondo'},
      { property: 'og:site_name', content: 'Ven y disfruta de nuestra carta variada'},
      { property: 'og:description', content: 'Ven y disfruta de nuestra carta variada' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://www.restaurantetorreondo.com' },
      { property: 'og:image', content: 'https://www.restaurantetorreondo.com/img/favicon.ico' }
    ]
  },
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  mounted() {
    this.$gtag.pageview(this.$route);
  },
  data() {
    return {
      entrantes: [
        {
          title: 'Paleta de bellota 100% ibérico con tostas y tomate KM0',
          price: '21€',
          allergen: ['gluten']
        },
        {
          title: 'Cecina de León con aliño de aceite de oliva virgen extra y lascas de Idiazabal',
          price: '15€',
          allergen: ['lacteos']
        },
        {
          title: 'Ensalada de ventresca con tomate y aceitunas',
          price: '19€',
          allergen: ['moluscos']
        },
        {
          title: 'Ensalada crunchy de pollo con vinagreta de miel y mostaza',
          price: '15,50€',
          allergen: ['gluten', 'huevos', 'lacteos']
        },
        {
          title: 'Sepia, huevo a baja temperatura, patatas y torreznos',
          price: '22€',
          allergen: ['huevos', 'crustaceos', 'moluscos', 'pescado']
        },
        {
          title: 'Ebi Gyozas estilo al chef con salsa teriyaki (6uds)',
          price: '12€',
          allergen: ['gluten', 'soja', 'pescado', 'sesamo']
        },
        {
          title: 'Rabas de bakalao',
          price: '10,50€',
          allergen: ['gluten', 'moluscos']
        },
        {
          title: 'Morcilla de Bakio con pimientos rojos asados',
          price: '9,50€',
          allergen: ['soja', 'lacteos']
        },
        {
          title: 'Croquetas caseras de ibéricos',
          price: '12€',
          allergen: ['lacteos', 'gluten', 'soja', 'huevos', 'cascara']
        },
        {
          title: 'Zamburiñas a la plancha',
          price: '15€',
          allergen: ['moluscos']
        },
        {
          title: 'Zarpa de pulpo a la brasa estilo al chef',
          price: '22€',
          allergen: ['lacteos', 'moluscos', 'gluten', 'huevos']
        },
      ],
      carnes: [
        {
          title: 'Carpaccio de txuleta de vaca madurada con lascas de parmesano y aliño de pistacho',
          price: '16€',
          allergen: ['lacteos', 'gluten', 'huevos', 'cascara'],
        },
        {
          title: 'Solomillo de vaca premium con guarnición',
          price: '20€',
          allergen: []
        },
        {
          title: 'Hamburguesa de vaca premium con guarnición',
          price: '15€',
          allergen: []
        },
        {
          title: 'Txuleta de vaca super premium',
          price: '53€/kg',
          allergen: []
        },
      ],
      postres: [
        {
          title: 'Tarta de queso con frutos rojos',
          price: '6,50€',
          allergen: ['lacteos', 'huevos', 'gluten']
        },
        {
          title: 'Tatin de manzana con helado',
          price: '6€',
          allergen: ['lacteos', 'huevos', 'gluten']
        },
        {
          title: 'Banana split con helado artesano',
          price: '7€',
          allergen: ['lacteos', 'gluten']
        },
        {
          title: 'Torrija caramelizada',
          price: '6,50€',
          allergen: ['lacteos', 'huevos', 'gluten']
        },
        {
          title: 'Sorbete de fruta al cava',
          price: '6€',
          allergen: ['lacteos', 'huevos', 'gluten']
        },
      ]
    };
  }
};
</script>
<style></style>
