<template>
  <div id="app notranslate">
    <router-view name="header" />
    <div class="wrapper">
      <router-view />
    </div>
    <router-view name="footer" />
  </div>
</template>
<script>
export default {
  name: 'App',
  metaInfo() {
    return {
      title: 'Restaurante Torreondo',
      htmlAttrs: {
        lang: 'es-ES'
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: 'Disfruta de nuestro menú del día de lunes a viernes, de 13:00 a 16:00' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { property: 'og:title', content: "Restaurante Torreondo - Disfruta de nuestro menú del día de lunes a viernes, de 13:00 a 16:00"},
        { property: 'og:site_name', content: 'Restaurante Torreondo'},
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' } 
      ]
    }
  }  
}
</script>
