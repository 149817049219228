<template>
  <navbar
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template slot="navbar-menu">
      <!-- EL RESTAURANTE -->
      <router-link to="/">
        <li class="nav-item" @click="setNav()">
          <a
            class="nav-link"
            href="#el-restaurante"
          >
            <font-awesome-icon :icon="['fa', 'home']"/>
            <p>&nbsp; El restaurante</p>
          </a>
        </li>
    </router-link>
      <!-- LA CARTA -->
      <router-link to="/carta">
        <li class="nav-item" @click="setNav()">
          <a
            class="nav-link"
            href="#la-carta"
          >
            <font-awesome-icon :icon="['fa', 'bars']"/>
            <p>&nbsp; La carta</p>
          </a>
        </li>
      </router-link>

      <!-- MENU FIN DE SEMANA -->
      <router-link to="/menu-fin-de-semana">
        <li class="nav-item" @click="setNav()">
          <a
            class="nav-link"
            href="#menu-fin-de-semana"
          >
            <font-awesome-icon :icon="['fa', 'utensils']"/>
            <p>&nbsp; Menú fin de semana</p>
          </a>
        </li>
      </router-link>

      <!-- HAMBURGEUSAS -->
      <router-link to="/hamburguesas">
        <li class="nav-item" @click="setNav()">
          <a
            class="nav-link"
            href="#hamburguesas"
          >
            <font-awesome-icon :icon="['fa', 'burger']"/>
            <p>&nbsp; Hamburguesas</p>
          </a>
        </li>
      </router-link>

      <!-- NUESTROS VINOS -->
      <router-link to="/vinos">
        <li class="nav-item" @click="setNav()">
          <a
            class="nav-link"
            href="#vinos"
          >
            <font-awesome-icon :icon="['fa', 'wine-bottle']"/>
            <p>&nbsp; Carta de vinos</p>
          </a>
        </li>
      </router-link>

      <!-- CONTACTO -->
      <router-link to="/contacto">
        <li class="nav-item" @click="setNav()">
          <a
            class="nav-link"
            href="#contacto"
          >
            <font-awesome-icon :icon="['fa', 'phone']"/>
            <p>&nbsp; Contacto</p>
          </a>
        </li>
        <!-- <li class="nav-item">
          <hr style="background-color: #A5D7E8;">
        </li> -->
      </router-link>

      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Síguenos en Instagram"
          data-placement="bottom"
          href="https://www.instagram.com/restaurantetorreondo"
          target="_blank"
          @click="sendGA4Event('instagram_click')"
        >
          <i class="fab fa-instagram"></i>
          <p class="d-lg-none d-xl-none">&nbsp; Instagram</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Llámanos"
          data-placement="bottom"
          href="tel:946042956"
          target="_blank"
          @click="sendGA4Event('call_click')"
        >
          <i class="fa fa-phone"></i>
          <p class="d-lg-none d-xl-none">&nbsp; Llámanos</p>
        </a>
      </li>
      <!-- <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Like us on Facebook"
          data-placement="bottom"
          href="mailto:restaurantetorreondo@gmail.com"
          @click="sendGA4Event('send_email')"
          target="_blank"
        >
          <i class="fa fa-envelope"></i>
          <p class="d-lg-none d-xl-none">&nbsp; Enviar Email</p>
        </a>
      </li> -->
    </template>
  </navbar>
</template>

<script>
import { DropDown, Navbar, NavLink } from '@/components';
import { Popover } from 'element-ui';
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    Navbar,
    [Popover.name]: Popover
  },
  methods: {
    setNav() {
      let htmlClasses = document.querySelector('html').classList;
      htmlClasses.remove('nav-open');
      let isOpen = htmlClasses.contains('nav-open');
      let eventToTrigger = isOpen ? 'open' : 'close';
      this.showMenu = isOpen;
      this.$emit(eventToTrigger);
      this.sendGA4Event('menu_click')
    },
    sendGA4Event(event) {
      this.$gtag.event(event, {'value': true});
    }
  }
};
</script>

<style scoped></style>
