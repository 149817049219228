import Vue from 'vue';
import App from './App.vue';

import router from './router';
import NowUiKit from './plugins/now-ui-kit';

import VideoBackground from 'vue-responsive-video-background-player';
import VueMeta from 'vue-meta';
import VueGtag from "vue-gtag";
import VueCarousel from 'vue-carousel';

// Fontawesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { dom } from "@fortawesome/fontawesome-svg-core";
library.add(fas);
library.add(far);
library.add(fab);

dom.watch();

Vue.config.productionTip = false;
Vue.use(NowUiKit);
Vue.use(VueMeta);
Vue.use(VueCarousel);
Vue.use(VueGtag, {
  appName: 'Restaurante Torreondo',
  pageTrackerScreenviewEnabled: true,
  config: { id: 'G-LE2DEMRYN7' },
}, router);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component('video-background', VideoBackground);

Vue.prototype.$appName = "Restaurante Torreondo";

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
